import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ICalibrationData, IGraphData } from 'shared/model/api.model';
import { DeviceBatteryStatus, IDevice } from 'shared/model/device.model';
import { APP_TIMESTAMP_FORMAT, APP_LOCAL_DATE_FORMAT, formatDate } from 'shared/utils/date-utils';
import { workspaceIsIbc, getUseCase, workspaceUseZonda } from 'shared/utils/workspace-utils';
import LabelValue from 'shared/widgets/labelValue';
import DeviceGroup from './deviceGroup';
import CapaMaxField from './fields/capaMaxField';
import { Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as Battery100PercentIcon } from 'shared/icons/Battery100PercentIcon.svg';
import { ReactComponent as Battery66PercentIcon } from 'shared/icons/Battery66PercentIcon.svg';
import { ReactComponent as Battery33PercentIcon } from 'shared/icons/Battery33PercentIcon.svg';
import { ReactComponent as BatteryProblemIcon } from 'shared/icons/BatteryProblemIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1)
    }
  })
);

const BATTERY_STATUS = Object.freeze({
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  EMPTY: 'empty'
});

function isKnownBatteryStatus(status: string | undefined): status is DeviceBatteryStatus {
  return Object.values(BATTERY_STATUS).includes(status as any);
}

const BATTERY_PERCENTAGE_ICON = Object.freeze({
  [BATTERY_STATUS.HIGH]: Battery100PercentIcon,
  [BATTERY_STATUS.MEDIUM]: Battery66PercentIcon,
  [BATTERY_STATUS.LOW]: Battery33PercentIcon,
  [BATTERY_STATUS.EMPTY]: BatteryProblemIcon
});

const BATTERY_EXPLANATION = Object.freeze({
  [BATTERY_STATUS.HIGH]: 'battery_full',
  [BATTERY_STATUS.MEDIUM]: 'battery_half_full',
  [BATTERY_STATUS.LOW]: 'battery_almost_empty',
  [BATTERY_STATUS.EMPTY]: 'battery_empty'
});
const BATTERY_TOOLTIP: Partial<Record<DeviceBatteryStatus, string>> = Object.freeze({
  [BATTERY_STATUS.HIGH]: 'battery_full_percent',
  [BATTERY_STATUS.MEDIUM]: 'battery_half_full_percent',
  [BATTERY_STATUS.LOW]: 'battery_almost_empty_percent'
});

export interface IDeviceInfosProps {
  device: IDevice;
  timeData?: IGraphData;
  positionData?: IGraphData;
  lastLevel?: ICalibrationData[];
}

const DeviceInfos = (props: IDeviceInfosProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const useZonda = workspaceUseZonda(settings);
  const isIbc = workspaceIsIbc(settings);
  const useCase = getUseCase(settings);
  const { device, timeData, positionData, lastLevel = [] } = props;

  return (
    <Box className={classes.root}>
      <LabelValue label={t('device_name')} value={device.device_name} />
      <LabelValue label={t('device_reference')} value={device.device_reference} />
      {isIbc && (
        <>
          <LabelValue label={t('article_code_identifier')} value={device.article_code_identifier} />
        </>
      )}
      <LabelValue
        label={t('device_starting_date')}
        value={formatDate(device.device_install_date)}
      />
      {isIbc && (
        <>
          <LabelValue
            label={t('device_fabrication_date')}
            value={formatDate(device.device_fabrication_date)}
          />
          <LabelValue
            label={t('device_next_maintenance_date')}
            value={formatDate(device.device_next_maintenance_date)}
          />
          <LabelValue
            label={t('device_end_life_date')}
            value={formatDate(device.device_end_life_date)}
          />
        </>
      )}
      <CapaMaxField device={device} showLabel />

      <LabelValue label={t('device_description')} value={device.device_description} />
      {timeData?.data_type === 'lastMessageReceived' && (
        <LabelValue
          label={t('lastMessageReceived')}
          value={formatDate(timeData.data_points[0].value?.toString(), APP_TIMESTAMP_FORMAT)}
        />
      )}
      {positionData?.configuration?.asset_type === 'mobile' && (
        <LabelValue
          label={t('lastGeolocation')}
          value={formatDate(
            positionData.data_points[0].timestamp?.toString(),
            APP_TIMESTAMP_FORMAT
          )}
        />
      )}
      <LabelValue label={t('device_content')} value={device.deviceContent?.device_content} />
      {useZonda && (
        <LabelValue label={t('reference')} value={device.deviceContent?.device_content_reference} />
      )}
      {!isIbc && (
        <>
          <LabelValue
            label={t('device_last_delivery')}
            value={formatDate(device.device_last_delivery, APP_LOCAL_DATE_FORMAT)}
          />
          {useCase === 'silo' && [
            <LabelValue label={t('device_supplement')} value={device.device_supplement} />,
            <LabelValue label={t('device_drug')} value={device.device_drug} />,
            <LabelValue label={t('device_content_type')} value={device.device_content_type} />
          ]}
          {lastLevel?.length > 0 && (
            <>
              <LabelValue
                label={t('device_last_calibration')}
                value={
                  lastLevel[0]?.calibration_date != null
                    ? formatDate(lastLevel[0]?.calibration_date, APP_TIMESTAMP_FORMAT)
                    : null
                }
              />
              <LabelValue
                label={t('last_calibration_level')}
                value={t('number_workspace_filling_unit', { value: lastLevel[0]?.quantity })}
              />
            </>
          )}
          <BatteryStatusLabel status={device.metadata.battery_status} />
        </>
      )}
      {device.last_seen && (
        <LabelValue
          label={t('device_last_seen')}
          value={formatDate(device.last_seen, APP_TIMESTAMP_FORMAT)}
        />
      )}
      <LabelValue
        label={t('groups_of_devices')}
        value={
          <Box>
            {device.groups.length &&
              device.groups.map(groupDef => (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  key={groupDef.group_id}
                >
                  <DeviceGroup group={groupDef} />
                  {groupDef.is_poi &&
                    new Date(device.metadata.poi_reassignation_date) > new Date() && (
                      <Typography variant="body2">
                        {t('poiReassignationDate')}:{' '}
                        {formatDate(device.metadata.poi_reassignation_date)}
                      </Typography>
                    )}
                </Box>
              ))}
          </Box>
        }
      />
    </Box>
  );
};

function BatteryStatusLabel({ status }: { status: string | undefined }) {
  const { t } = useTranslation();
  const theme = useTheme();
  if (!isKnownBatteryStatus(status)) {
    return <LabelValue label={t('battery_level')} value={t('unknown')} />;
  }

  const explanation = t(BATTERY_EXPLANATION[status]);
  const tooltip = BATTERY_TOOLTIP[status] && t(BATTERY_TOOLTIP[status]);
  const IconComponent = isKnownBatteryStatus(status)
    ? BATTERY_PERCENTAGE_ICON[status]
    : BatteryProblemIcon;

  return (
    <LabelValue
      label={t('battery_level')}
      value={
        <Box display="flex" alignItems="center">
          {tooltip ? (
            <Tooltip title={tooltip}>
              <IconComponent
                color={
                  status === BATTERY_STATUS.HIGH || status === BATTERY_STATUS.MEDIUM
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
                style={{ marginRight: theme.spacing(1) }}
              />
            </Tooltip>
          ) : (
            <IconComponent
              color={
                status === BATTERY_STATUS.HIGH || status === BATTERY_STATUS.MEDIUM
                  ? theme.palette.success.main
                  : theme.palette.error.main
              }
              style={{ marginRight: theme.spacing(1) }}
            />
          )}
          <Box
            component="span"
            style={{
              color:
                status === BATTERY_STATUS.HIGH || status === BATTERY_STATUS.MEDIUM
                  ? theme.palette.success.main
                  : theme.palette.error.main
            }}
          >
            {explanation}
          </Box>
        </Box>
      }
    />
  );
}

export default DeviceInfos;
