import { nanolikeDataType } from 'shared/model/api.model';
import { nanolikeCallbackType } from 'shared/model/callback.model';
import { graphType } from 'shared/model/graph.model';
export interface IExportCSV {
  maxMonths: number;
  ibcDataTypes: nanolikeDataType[];
  siloDataTypes: nanolikeDataType[];
  delimiter: string;
}

export interface IMapCenter {
  lat: number;
  lng: number;
}

export interface IMapConfig {
  defaultZoom: number;
  defaultCenter: IMapCenter;
}

export interface ILevelConfig {
  high: number;
  medium: number;
  low: number;
}

export interface IGraphTypeConfig {
  maxDevices: number;
  maxTypes: number;
}

export interface IConfig {
  googleAnalytics: string;
  refreshTokenTimeout: number;
  refreshGraphInterval: number;
  exportCSV: IExportCSV;
  callbacks: { [key in nanolikeCallbackType]: nanolikeDataType[] };
  map: IMapConfig;
  graphColors: string[];
  dataTypeWithDynamicFillingUnit: nanolikeDataType[];
  graphTypes: { [key in graphType]: IGraphTypeConfig };
}

export const config: IConfig = {
  googleAnalytics: 'G-CE0JB3621S',
  refreshTokenTimeout: 5 * 60 * 1000, // 5 minutes
  refreshGraphInterval: 30 * 60 * 1000, // 30 minutes
  exportCSV: {
    maxMonths: 4,
    siloDataTypes: ['level_percent', 'level_t', 'missingWeight', 'daily_analysis'],
    ibcDataTypes: ['level_percent', 'level_liter', 'temperature'],
    delimiter: ';'
  },
  callbacks: {
    BOTH: ['temperature', 'level_percent', 'level_kg', 'level_t', 'level_liter', 'clientName']
  },
  map: {
    defaultZoom: 7,
    defaultCenter: {
      lat: 43.546019,
      lng: 1.513667
    }
  },
  graphColors: [
    '#1abc9c',
    '#f1c40f',
    '#e67e22',
    '#c0392b',
    '#2c3e50',
    '#2980b9',
    '#27ae60',
    '#276450',
    '#EB3F0E',
    '#5DCAF3',
    '#C516D5',
    '#858D97'
  ],
  dataTypeWithDynamicFillingUnit: [
    'capa_max',
    'daily_analysis',
    'last_calibration',
    'level_kg',
    'level_liter',
    'level_t',
    'filling',
    'level',
    'levelJPlus1',
    'levelJPlus2',
    'levelJPlus3',
    'levelJPlus4',
    'levelJPlus5',
    'levelJPlus6',
    'levelJPlus7',
    'levelJPlus1Kg',
    'levelJPlus2Kg',
    'levelJPlus3Kg',
    'levelJPlus4Kg',
    'levelJPlus1T',
    'levelJPlus2T',
    'levelJPlus3T',
    'levelJPlus4T',
    'levelPredictions',
    'max15days',
    'mean15days',
    'missingLiter',
    'missingLiterJPlus1',
    'missingLiterJPlus2',
    'missingLiterJPlus3',
    'missingLiterJPlus4',
    'missingLiterJPlus5',
    'missingLiterJPlus6',
    'missingLiterJPlus7',
    'missingWeight',
    'missingWeightJPlus1',
    'missingWeightJPlus2',
    'missingWeightJPlus3',
    'missingWeightJPlus4'
  ],
  graphTypes: {
    curve: {
      maxDevices: 20,
      maxTypes: 5
    },
    table: {
      maxDevices: 100,
      maxTypes: 20
    },
    map: {
      maxDevices: 1000,
      maxTypes: 1
    }
  }
};

export default config;
