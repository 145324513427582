import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import LoginCard from './login-card';
import LeftLanding from '../../shared/widgets/leftLanding';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      height: `100vh`,
      '&>*': {
        width: '100%'
      }
    },
    box: {
      // height: `100vh`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  })
);

const Login = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={0}>
      <LeftLanding />
      <Grid
        item
        sm={6}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box className={classes.box} p={4}>
          <LoginCard />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
