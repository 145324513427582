import Box from '@material-ui/core/Box';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import DataTable from 'shared/widgets/dataTable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Switch, Theme, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PrivateComponent from 'shared/auth/privateComponent';
import { GroupAutoOrder } from 'shared/model/autoOrder.model';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import {
  activateGroupAutoOrder,
  deleteGroupAutoOrder,
  fetchAutoOrders
} from 'shared/reducers/autoOrdersSlice';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import moment from 'moment';
import AutoOrderDetails from './autoOrderDetails';
import { useIsAuthorised } from 'shared/auth/auth-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    }
  })
);

const AutoOrdersControlPanel = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(({ autoOrder }: IRootState) => autoOrder.loading);
  const dispatch = useDispatch();
  const groupAutoOrders = useSelector(({ autoOrder }: IRootState) => autoOrder.groupAutoOrders);
  const [selectedRows, setSelectedRows] = useState<GroupAutoOrder[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  useEffect(() => {
    dispatch(fetchAutoOrders());
  }, [dispatch]);

  const columns: IDataTableColumn<GroupAutoOrder>[] = useMemo(() => {
    const handleStatusChange = (groupAutoOrder: GroupAutoOrder) => () => {
      dispatch(
        activateGroupAutoOrder({
          ...groupAutoOrder,
          is_active: !groupAutoOrder.is_active
        })
      );
    };
    return [
      {
        selector: 'plant',
        name: t('plant'),
        sortable: true,
        grow: 4,
        format: row => (
          <Box display="flex" alignItems="center">
            <Box fontWeight="fontWeightBold" data-tag="allowRowEvents">
              {row.group_name}
            </Box>
          </Box>
        )
      },
      {
        selector: row => row.device_auto_orders.length,
        name: t('silo_number'),
        sortable: true,
        grow: 1,
        format: row => (
          <Box display="flex" alignItems="center">
            {row.device_auto_orders?.length}
          </Box>
        )
      },
      {
        selector: row => row.ship_to,
        name: t('ship_to'),
        sortable: true,
        grow: 1,
        format: row => (
          <Box display="flex" alignItems="center">
            {row.ship_to}
          </Box>
        )
      },
      {
        selector: row => row.sold_to,
        name: t('sold_to'),
        sortable: true,
        grow: 1,
        format: row => (
          <Box display="flex" alignItems="center">
            {row.sold_to}
          </Box>
        )
      },
      {
        selector: row => row.last_autoorder_date,
        name: t('last_order'),
        grow: 4,
        sortable: true,
        hide: 'sm',
        format: row => {
          if (!row.last_autoorder_date) return null;
          const humanize = moment(row.last_autoorder_date).fromNow();
          const dateStr = formatDate(row.last_autoorder_date, APP_TIMESTAMP_FORMAT);
          return (
            <Tooltip title={dateStr}>
              <span>{humanize}</span>
            </Tooltip>
          );
        }
      },
      {
        selector: row => row.is_active,
        name: t('active'),
        sortable: true,
        grow: 1,
        format: row => {
          return (
            <div style={{ textAlign: 'right' }}>
              <PrivateComponent
                resource="AutoOrderActive"
                operation={['UPDATE']}
                otherwise={<Switch checked={row.is_active} disabled color="primary" />}
              >
                <Switch
                  checked={row.is_active}
                  color="primary"
                  onChange={handleStatusChange(row)}
                />
              </PrivateComponent>
            </div>
          );
        }
      }
    ];
  }, [t, dispatch]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const onSuccess = () => {
      dispatch(fetchAutoOrders());
      setToggleCleared(!toggleCleared);
    };

    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteGroupAutoOrder(selectedRows[i]));
      }
      onSuccess();
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <PrivateComponent resource="AutoOrder" operation={['UPDATE']}>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/auto-order/${selectedRows[0].group_id}`}
            >
              <EditIcon />
            </Button>
          </PrivateComponent>
        )}
        <PrivateComponent resource="AutoOrder" operation={['DELETE']}>
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, dispatch, selectedRows, toggleCleared]);

  return (
    <Box p={1}>
      <DataTable
        title={t('auto_orders')}
        defaultSortField="plant"
        columns={columns}
        data={groupAutoOrders}
        selectableRows={useIsAuthorised('AutoOrder', ['DELETE', 'UPDATE'])}
        progressPending={loading}
        expandableRows
        expandableRowsComponent={
          // @ts-ignore props are passed to the component
          <AutoOrderDetails />
        }
        expandOnRowClicked
        expandableRowsHideExpander
        onSelectedRowsChange={handleRowSelected}
        actions={
          <PrivateComponent resource="AutoOrder" operation={['CREATE']}>
            <Button color="primary" variant="contained" component={Link} to={`/auto-order/new`}>
              <AddIcon /> {t('new_auto_order')}
            </Button>
          </PrivateComponent>
        }
        contextActions={contextActions}
        clearSelectedRows={toggleCleared}
      />
    </Box>
  );
};

export default AutoOrdersControlPanel;
