import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPng from 'shared/icons/error.png';
import { ReactComponent as DefaultIbcIcon } from 'shared/icons/ibc.svg';
import { ReactComponent as IbcErrorIcon } from 'shared/icons/ibc_error.svg';
import { ReactComponent as IbcLevelProblemIcon } from 'shared/icons/IbcLevelProblem.svg';
import calibrateIcon from '../../icons/calibrating24h.png';
import { ReactComponent as DefaultSiloIcon } from 'shared/icons/silo.svg';
import { ReactComponent as DefaultTankIcon } from 'shared/icons/tank.svg';
import tankPng from 'shared/icons/tank.svg';
import siloPng from 'shared/icons/silo.svg';
import ibcPng from 'shared/icons/ibc.svg';
import { DeviceStatus, IDevice } from 'shared/model/device.model';
import { OKSTATUS } from 'shared/utils/device-utils';
import { Alert } from '@material-ui/lab';
import { AcUnit, WifiOff } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Moment from 'react-moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '32px',
      height: '32px'
    },
    largeIcon: {
      width: '54px',
      height: '54px'
    },
    combineIcon: {
      width: '24px',
      height: '24px',
      color: '#555',
      marginLeft: '5px',
      marginRight: '5px'
    },
    pendingOrError: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    error: {
      color: theme.palette.error.main,
      textAlign: 'center'
    },
    pending: {
      color: '#2E48A7',
      textAlign: 'center'
    }
  })
);
interface IDeviceIconProps {
  device: IDevice;
}

export const DeviceIconPng = (props: { is_silo: boolean; is_tank: boolean }) => {
  const { is_silo, is_tank } = props;
  if (is_tank) return tankPng;
  if (is_silo) return siloPng;
  return ibcPng;
};

const DeviceIcon = (props: IDeviceIconProps) => {
  const { device } = props;

  if (device.is_combined || device.is_silo) {
    return null;
  }

  return (
    <Box>
      {device.is_tank ? <TankIcon status={device.status} /> : <IbcIcon status={device.status} />}
    </Box>
  );
};

interface IIconProps {
  status: DeviceStatus;
}

interface IPendingOrErrorDeviceIconProps extends IIconProps {
  legend?: boolean;
  metadata?: any;
  installDate?: Moment | undefined;
}
// @ts-ignore
const getProblemCause = (problem_cause: string) =>
  ({
    out_of_data: {
      icon: <WifiOff fontSize="large" color="error" />,
      tr_key: problem_cause
    },
    trapped_in_ice: {
      icon: <AcUnit fontSize="large" color="error" />,
      tr_key: problem_cause
    }
  }[problem_cause] ?? {
    icon: (
      <img
        src={ErrorPng}
        style={{
          width: '54px',
          height: '54px'
        }}
        alt=""
      />
    ),
    tr_key: 'error'
  });
export const PendingOrErrorDeviceIcon = (props: IPendingOrErrorDeviceIconProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status, metadata, installDate } = props;
  return (
    <Box className={classes.pendingOrError}>
      {['calibrating', 'pending'].includes(status) ? (
        // @ts-ignore
        status === 'pending' && !!metadata?.info_to_calibrate ? (
          <Alert severity="warning" color="warning" icon={<></>}>
            {t('devices_status.pending.explanation')}
          </Alert>
        ) : (
          <img src={calibrateIcon} className={classes.largeIcon} alt="" />
        )
      ) : ['problem', 'error', 'calibration_problem', 'level_problem'].includes(status) ? (
        <>
          {getProblemCause(status === 'problem' && metadata?.problem_cause).icon}
          <Typography className={classes.error}>
            {t(
              `devices_status.${
                getProblemCause(status === 'problem' && metadata?.problem_cause).tr_key
              }.explanation`
            )}
          </Typography>
        </>
      ) : ['ok'].includes(status) && installDate ? (
        <Alert severity="warning" color="warning" icon={<></>}>
          {t('calibration_before_15_days')}
        </Alert>
      ) : null}
    </Box>
  );
};

export const IbcIcon = (props: IIconProps) => {
  const classes = useStyles();
  const { status } = props;

  if (status === 'ok') return <DefaultIbcIcon className={classes.icon} />;
  if (status === 'level_problem') return <IbcLevelProblemIcon className={classes.icon} />;
  return <IbcErrorIcon className={classes.icon} />;
};

export const SiloIcon = () => {
  const classes = useStyles();
  return <DefaultSiloIcon className={classes.icon} />;
};

/**
 * Special icon used for GACHES
 */
export const TankIcon = (props: IIconProps) => {
  const classes = useStyles();
  const { status } = props;
  return OKSTATUS.includes(status) ? (
    <DefaultTankIcon className={classes.icon} />
  ) : (
    <PendingOrErrorDeviceIcon status={status} />
  );
};

export const CombineIcon = () => {
  const classes = useStyles();
  return <LinkIcon className={classes.combineIcon} />;
};

export default DeviceIcon;
